import { ChangeEvent, FC, MouseEvent, useEffect, useRef } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { Box, Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { useFiles } from '../../../features/files';
import { IconDownload, IconUpload } from '../../../common/icons';
import { useAppDispatch, useAppSelector } from '../../../store';
import { WantedListTypeEnum } from '../../../features/wanted-list/enums';
import {
  fetchWantedListAll,
  fetchWantedListCreate,
  fetchWantedListDelete,
} from '../../../features/wanted-list/thunks';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const WantedListPage: FC = () => {
  const dispatch = useAppDispatch();
  const { result } = useAppSelector((state) => state.wantedListSlice.wantedList);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const { handleInputUpload } = useFiles();
  const { t } = useTranslation();
  const handleClick = (type: WantedListTypeEnum) => {
    if (type === WantedListTypeEnum.National) {
      inputRef?.current?.click();
    }
    if (type === WantedListTypeEnum.International) {
      inputRef2?.current?.click();
    }
  };

  const nationalFiles = result?.filter((item) => item.type === WantedListTypeEnum.National);
  const internationalFiles = result?.filter(
    (item) => item.type === WantedListTypeEnum.International,
  );

  const handleChangeInput = async (e: ChangeEvent<HTMLInputElement>, type: WantedListTypeEnum) => {
    const res = await handleInputUpload(e);
    await dispatch(fetchWantedListCreate({ type, files: [res], file_id: res!.id })).then(() =>
      dispatch(fetchWantedListAll()),
    );
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    dispatch(fetchWantedListDelete(id)).then(() => dispatch(fetchWantedListAll()));
  };

  useEffect(() => {
    dispatch(fetchWantedListAll());
  }, [dispatch]);

  return (
    <CabinetContainer>
      <Card sx={{ mb: 2 }}>
        <CardContent
          component={Stack}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{t('national.list')}</Typography>
          <Stack direction="row" spacing={1}>
            {nationalFiles?.map((item, idx) => (
              <Stack direction="row" key={idx}>
                <Box
                  component={Link}
                  to={`${ENDPOINT_BASE_URL}/images/${item?.files[0].saved_name}`}
                  download
                  sx={{ text: 'none' }}
                  target="_self"
                  minWidth={170}
                >
                  <Box
                    component={Button}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<IconDownload />}
                    display={{ xs: 'none', md: 'flex' }}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: { xs: 'none', lg: 'block', textDecoration: 'none' },
                        textAlign: 'center',
                      }}
                    >
                      {t('action.download')}
                    </Box>
                  </Box>
                </Box>
                <Button variant="contained" color="error" onClick={(e) => handleDelete(e, item.id)}>
                  {t('action.delete')}
                </Button>
              </Stack>
            ))}
            <TextField
              type="file"
              sx={{ display: 'none' }}
              inputRef={inputRef}
              onChange={(e) => handleChangeInput(e as any, WantedListTypeEnum.National)}
            />
            <Button
              disabled={Boolean(nationalFiles?.length)}
              startIcon={<IconUpload />}
              variant="contained"
              onClick={() => handleClick(WantedListTypeEnum.National)}
            >
              {t('action.upload')}
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardContent
          component={Stack}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{t('international.list')}</Typography>
          <Stack direction="row" spacing={1}>
            {internationalFiles?.map((item, idx) => (
              <Stack direction="row">
                <Box
                  component={Link}
                  to={`${ENDPOINT_BASE_URL}/images/${item?.files[0].saved_name}`}
                  download
                  sx={{ text: 'none' }}
                  target="_self"
                  minWidth={170}
                >
                  <Box
                    component={Button}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<IconDownload />}
                    display={{ xs: 'none', md: 'flex' }}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: { xs: 'none', lg: 'block', textDecoration: 'none' },
                        textAlign: 'center',
                      }}
                    >
                      {t('action.download')}
                    </Box>
                  </Box>
                </Box>
                <Button variant="contained" color="error" onClick={(e) => handleDelete(e, item.id)}>
                  {t('ation.delete')}
                </Button>
              </Stack>
            ))}
            <TextField
              type="file"
              sx={{ display: 'none' }}
              inputRef={inputRef2}
              onChange={(e) => handleChangeInput(e as any, WantedListTypeEnum.International)}
            />

            <Button
              disabled={Boolean(internationalFiles?.length)}
              startIcon={<IconUpload />}
              variant="contained"
              onClick={() => handleClick(WantedListTypeEnum.International)}
            >
              Yuklash
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </CabinetContainer>
  );
};
