import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_ORGANIZATIONAL_STRUCTURE_CREATE_ONE,
  ENDPOINT_ORGANIZATIONAL_STRUCTURE_DELETE_ONE,
  ENDPOINT_ORGANIZATIONAL_STRUCTURE_LIST,
} from '../endpoints';
import { IOrganizationalStructureResponse } from '../models';

export class OrganizationalStructureApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(): Promise<IOrganizationalStructureResponse[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_ORGANIZATIONAL_STRUCTURE_LIST))
      .then((response) => response.data);
  }

  public createOne(body: { files: any }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_ORGANIZATIONAL_STRUCTURE_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public deleteOne(body: { id: string }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_ORGANIZATIONAL_STRUCTURE_DELETE_ONE), body)
      .then((response) => response.data);
  }
}
