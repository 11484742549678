import { combineReducers } from '@reduxjs/toolkit';
import { accountSlice } from '../features/account/slices/account-slice';
import { meSlice } from '../features/auth/slices';
import { faqSlice } from '../features/faq/slices';
import { reportSlice } from '../features/home/slices';
import { newsSlice } from '../features/news/slices';
import { regionalAdministrationsSlice } from '../features/regional-administrations/slices';
import { regionsSlice } from '../features/regions/slices';
import { userSlice } from '../features/users/slices';
import { openInfoSlice } from '../features/open-info/slices';
import { emailSlice } from '../features/email/slices';
import { managementSlice } from '../features/management/slices';
import { wantedListSlice } from '../features/wanted-list/slices';
import { organizationalStructureSlice } from '../features/organizational-structure/slice';

export const rootReducer = combineReducers({
  me: meSlice.reducer,
  news: newsSlice.reducer,
  user: userSlice.reducer,
  report: reportSlice.reducer,
  account: accountSlice.reducer,
  regionalAdministrations: regionalAdministrationsSlice.reducer,
  regions: regionsSlice.reducer,
  faq: faqSlice.reducer,
  openInfo: openInfoSlice.reducer,
  email: emailSlice.reducer,
  management: managementSlice.reducer,
  [wantedListSlice.name]: wantedListSlice.reducer,
  [organizationalStructureSlice.name]: organizationalStructureSlice.reducer,
});
