import { ManagementActionDelete, ManagementActionEdit, ManagementActionView } from '../components';
import { Localize } from '../../../components/localize';
import { IColumn } from '../../../common/models';
import { IManagement } from '../models';
import { MANAGEMENT_OFFICER_POSITION, MANAGEMENT_OFFICER_NAME } from '../constants';

export const MANAGEMENT_COLUMNS: IColumn[] = [
  {
    dataKey: MANAGEMENT_OFFICER_NAME,
    label: 'management.name',
    renderItem: ({ name }: IManagement) => <Localize data={name} />,
  },
  {
    dataKey: MANAGEMENT_OFFICER_POSITION,
    label: 'management.position',
    renderItem: ({ position }: IManagement) => <Localize data={position} />,
  },
  {
    dataKey: '',
    label: 'action.edit',
    width: '1%',
    renderItem: ({ id }: IManagement) => <ManagementActionEdit id={id} />,
  },
  {
    dataKey: '',
    label: 'action.delete',
    width: '1%',
    renderItem: ({ id }: IManagement) => <ManagementActionDelete id={id} />,
  },
  {
    dataKey: '',
    label: 'action.view',
    width: '1%',
    renderItem: (data: IManagement) => <ManagementActionView data={data} />,
  },
];
