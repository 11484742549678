import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Card, CardContent, Grid, CardHeader, IconButton } from '@mui/material';
import { useEntityForm } from '../../../entities/hooks';
import { TextFieldController } from '../../../../components/inputs';
import {
  LOCALE_CR,
  LOCALE_UZ,
  LOCALE_RU,
  LOCALE_EN,
  ENDPOINT_BASE_URL,
} from '../../../../common/constants';
import {
  MANAGEMENT_OFFICER_FILES,
  MANAGEMENT_OFFICER_NAME,
  MANAGEMENT_OFFICER_ORDER_NUMBER,
  MANAGEMENT_OFFICER_POSITION,
} from '../../constants';
import { IconClearAll, IconDeleteCanOutline, IconSave, IconUpload } from '../../../../common/icons';
import { IForm } from '../../../../common/models';
import { ManagementSchema } from '../../schema';
import { IManagement } from '../../models';
import { useFiles } from '../../../files';

export const ManagementForms: FC<IForm<IManagement>> = ({ defaultValues, onValid, isLoading }) => {
  const { t } = useTranslation();
  const imageRef = useRef<any>(null);
  const [files, setFiles] = useState<any>(defaultValues.files);
  const { handleInputUpload } = useFiles();
  const { control, setValue, onReset, onSubmit } = useEntityForm<IManagement>({
    schema: ManagementSchema,
    defaultValues,
    onValid,
  });

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const res = await handleInputUpload(e);
    setFiles(res);
  };

  const handleFileDelete = () => {
    setFiles(null);
    setValue(MANAGEMENT_OFFICER_FILES, null);
  };

  useEffect(() => {
    if (files) {
      setValue(MANAGEMENT_OFFICER_FILES, files);
    }
  }, [files, setValue]);

  return (
    <Box onSubmit={onSubmit} component="form">
      <Stack direction="row" justifyContent="flex-end" spacing={1.5} mb={2.5}>
        <Button
          type="reset"
          variant="contained"
          color="secondary"
          startIcon={<IconClearAll />}
          disabled={isLoading}
          onClick={onReset}
        >
          {t('action.clear')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<IconSave />}
          disabled={isLoading}
        >
          {t('action.save')}
        </Button>
      </Stack>
      <Card>
        <CardHeader title={t('management.form.title')} />
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_NAME, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('management.field.name.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_NAME, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('management.field.name.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_NAME, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('management.field.name.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_POSITION, LOCALE_CR].join('.')}
                disabled={isLoading}
                label={t('management.field.position.cr')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_POSITION, LOCALE_UZ].join('.')}
                disabled={isLoading}
                label={t('management.field.position.uz')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_POSITION, LOCALE_RU].join('.')}
                disabled={isLoading}
                label={t('management.field.position.ru')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                control={control}
                name={[MANAGEMENT_OFFICER_POSITION, LOCALE_EN].join('.')}
                disabled={isLoading}
                label={t('management.field.position.en')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldController
                type="number"
                control={control}
                name={MANAGEMENT_OFFICER_ORDER_NUMBER}
                disabled={isLoading}
                label={t('management.field.order.number')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {files ? (
                <Stack direction="row" alignItems="flex-start" spacing={1}>
                  <img
                    alt="alt"
                    style={{ width: '50%' }}
                    loading="lazy"
                    src={`${ENDPOINT_BASE_URL}/images/${files.saved_name}`}
                  />
                  <IconButton color="error" onClick={handleFileDelete}>
                    <IconDeleteCanOutline />
                  </IconButton>
                </Stack>
              ) : (
                <Box>
                  <Button
                    // fullWidth
                    sx={{ justifySelf: 'center' }}
                    startIcon={<IconUpload />}
                    variant="contained"
                    color="primary"
                    onClick={() => imageRef?.current?.click()}
                  >
                    {t('upload.director.photo')}
                  </Button>
                  <input
                    style={{ display: 'none' }}
                    ref={imageRef}
                    type="file"
                    accept=".jpg,jpeg,image/jpg,image/jpeg,.png,image/png"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
