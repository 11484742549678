import { ChangeEvent, FC, useEffect, useRef } from 'react';
import { CabinetContainer } from '../../../components/cabinet-container';
import { Button, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { IconDeleteCanOutline, IconUpload } from '../../../common/icons';
import { useFiles } from '../../../features/files';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  fetchAllOrganizationalStructureList,
  fetchOrganizationalStructureCreate,
  fetchOrganizationalStructureDelete,
} from '../../../features/organizational-structure/thunks';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import { useTranslation } from 'react-i18next';

export const OrganizationalStructurePage: FC = () => {
  const fileRef = useRef<any>(null);
  const { t } = useTranslation();
  const { result, isLoading } = useAppSelector(
    (state) => state.organizationalStructureSlice.organizationalStructure,
  );
  const dispatch = useAppDispatch();
  const { handleInputUpload } = useFiles();

  const handleDeleteFile = () => {
    dispatch(fetchOrganizationalStructureDelete({ id: result![0].id })).then(() =>
      dispatch(fetchAllOrganizationalStructureList()),
    );
  };

  const handleUploadFile = () => {
    fileRef?.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = await handleInputUpload(e);
    if (uploadedFile) {
      await dispatch(fetchOrganizationalStructureCreate({ files: uploadedFile })).then(() =>
        dispatch(fetchAllOrganizationalStructureList()),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAllOrganizationalStructureList());
  }, [dispatch]);

  return (
    <CabinetContainer>
      <Typography mb={3} variant="h3">
        {t('organizational.structure')}
      </Typography>
      <Card>
        <CardContent component={Stack} alignItems="flex-start" spacing={2} direction="row">
          {result && result?.length > 0 && result[0].files && result[0].files.saved_name && (
            <img
              alt={t('organizational.structure')}
              src={`${ENDPOINT_BASE_URL}/images/${result[0].files.saved_name}`}
              width={600}
            />
          )}
          {result && result?.length > 0 && (
            <IconButton color="error" onClick={handleDeleteFile}>
              <IconDeleteCanOutline />
            </IconButton>
          )}

          {!result?.length && <Typography variant="h5">{t('is.not.exist')}</Typography>}

          <input
            style={{ display: 'none' }}
            ref={fileRef}
            type="file"
            accept=".jpg,jpeg,image/jpg,image/jpeg,.png,image/png"
            onChange={handleFileChange}
          />
          <Button
            sx={{ marginLeft: 'auto !important' }}
            disabled={isLoading || Boolean(result && result?.length > 0 && result[0].id)}
            startIcon={<IconUpload />}
            variant="contained"
            onClick={handleUploadFile}
          >
            {t('action.upload')}
          </Button>
        </CardContent>
      </Card>
    </CabinetContainer>
  );
};
