import { createAsyncThunk } from '@reduxjs/toolkit';
import { WantedListApi } from '../http';
import { IWantedListCreate } from '../models';

const wantedListApi = new WantedListApi();

export const fetchWantedListAll = createAsyncThunk(
  'wanted-list/all',
  async (_, { rejectWithValue }) => {
    try {
      return await wantedListApi.findAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchWantedListCreate = createAsyncThunk(
  'wanted-list/create',
  async (body: IWantedListCreate, { rejectWithValue }) => {
    try {
      return await wantedListApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchWantedListDelete = createAsyncThunk(
  'wanted-list/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      return await wantedListApi.deleteOne({ id });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
