import { BaseApi } from '../../../common/axios';
import { ENDPOINT_BASE_URL } from '../../../common/constants';
import {
  ENDPOINT_WANTED_LIST_CREATE_ONE,
  ENDPOINT_WANTED_LIST_DELETE_ONE,
  ENDPOINT_WANTED_LIST_FIND_ALL,
} from '../endpoints';
import { IWantedListCreate, IWantedListResponse } from '../models';

export class WantedListApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl = ENDPOINT_BASE_URL;
  }

  public findAll(): Promise<IWantedListResponse[]> {
    return this.httpClient
      .post(this.url(ENDPOINT_WANTED_LIST_FIND_ALL))
      .then((response) => response.data);
  }

  public createOne(body: IWantedListCreate): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_WANTED_LIST_CREATE_ONE), body)
      .then((response) => response.data);
  }

  public deleteOne(body: { id: string }): Promise<any> {
    return this.httpClient
      .post(this.url(ENDPOINT_WANTED_LIST_DELETE_ONE), body)
      .then((response) => response.data);
  }
}
