import { FC, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form/dist/types/form';
import { Controller, Noop } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export type TextFieldControllerProps = TextFieldProps & {
  control: Control<any>;
  name: string;
};

export const TextFieldController: FC<TextFieldControllerProps> = ({ control, name, ...props }) => {
  const { t } = useTranslation();

  const handleBlur = (onBlur?: Noop) => (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur();
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onBlur, value, ...fieldProps }, fieldState: { error } }) => (
        <TextField
          {...{ ...props, ...fieldProps }}
          value={value || value === 0 ? value : ''}
          onBlur={handleBlur(onBlur)}
          inputRef={ref}
          error={!!error?.message}
          helperText={error?.message ? t(error.message) : null}
          autoComplete="new-password"
        />
      )}
    />
  );
};
