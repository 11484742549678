import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrganizationalStructureApi } from '../http';

const organizationalStructureApi = new OrganizationalStructureApi();

export const fetchAllOrganizationalStructureList = createAsyncThunk(
  'organizationalStructure/all',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationalStructureApi.findAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationalStructureCreate = createAsyncThunk(
  'organizationalStructure/create',
  async (body: { files: any }, { rejectWithValue }) => {
    try {
      return await organizationalStructureApi.createOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationalStructureDelete = createAsyncThunk(
  'organizationalStructure/delete',
  async (body: { id: string }, { rejectWithValue }) => {
    try {
      return await organizationalStructureApi.deleteOne(body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
