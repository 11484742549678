import { RouteObject } from 'react-router-dom';
import { ROUTE_WANTED_LIST } from '../../../common/constants';
import { WantedListPage } from '../../../pages/admin/wanted-list';

export const wantedListRoutes: RouteObject[] = [
  {
    path: ROUTE_WANTED_LIST,
    element: <WantedListPage />,
  },
];
