import { createSlice } from '@reduxjs/toolkit';
import { fetchWantedListAll } from '../thunks';
import { IWantedListResponse } from '../models';

interface IWantedListSlice {
  wantedList: {
    isLoading: boolean;
    result: IWantedListResponse[] | null;
    error: any;
  };
}

const initialState: IWantedListSlice = {
  wantedList: { isLoading: false, result: null, error: null },
};
export const wantedListSlice = createSlice({
  name: 'wantedListSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchWantedListAll.pending, (state) => {
        state.wantedList = {
          ...state.wantedList,
          isLoading: true,
        };
      })
      .addCase(fetchWantedListAll.fulfilled, (state, { payload }) => {
        state.wantedList = {
          ...state.wantedList,
          isLoading: false,
          result: payload,
        };
      })
      .addCase(fetchWantedListAll.rejected, (state, { payload }) => {
        state.wantedList = {
          ...state.wantedList,
          isLoading: false,
          error: payload,
        };
      }),
});
