import { createSlice } from '@reduxjs/toolkit';
import { fetchAllOrganizationalStructureList } from '../thunks';
import { IOrganizationalStructureResponse } from '../models';

interface IOrganizationalStructureInitialState {
  organizationalStructure: {
    isLoading: boolean;
    result: IOrganizationalStructureResponse[] | null;
    error: any;
  };
}

const initialState: IOrganizationalStructureInitialState = {
  organizationalStructure: {
    isLoading: false,
    result: null,
    error: null,
  },
};

export const organizationalStructureSlice = createSlice({
  name: 'organizationalStructureSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrganizationalStructureList.pending, (state) => {
        state.organizationalStructure = {
          ...state.organizationalStructure,
          isLoading: true,
          error: null,
        };
      })
      .addCase(fetchAllOrganizationalStructureList.fulfilled, (state, { payload }) => {
        state.organizationalStructure = {
          ...state.organizationalStructure,
          isLoading: false,
          result: payload,
        };
      })
      .addCase(fetchAllOrganizationalStructureList.rejected, (state, { payload }) => {
        state.organizationalStructure = {
          ...state.organizationalStructure,
          isLoading: false,
          error: payload,
        };
      });
  },
});
